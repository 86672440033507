<template>
  <div id="app">
    <LoadingScreen></LoadingScreen>
    <NavSection></NavSection>
    <router-view />
    <FooterSection></FooterSection>
    <ConnectionStatus></ConnectionStatus>
  </div>
</template>
<script>
import Pusher from "pusher-js";

import NavSection from "./components/NavSection.vue";
import FooterSection from "./components/FooterSection.vue";
import LoadingScreen from "./components/LoadingScreen.vue";
import ConnectionStatus from "./components/ConnectionStatus.vue";

Pusher.logToConsole = false;

export default {
  name: "App",
  components: {
    NavSection,
    FooterSection,
    LoadingScreen,
    ConnectionStatus,
  },
  mounted: function () {
    if (this.$store.state.authenticated) {
      this.connect();
    }
  },
  methods: {
    connect: function () {
      // Init Pusher
      var pusher = new Pusher("a7840956a96c24bcf440", {
        cluster: "us3",
        authEndpoint: this.$store.state.root_url + "/pusher/auth",
        auth: {
          headers: {
            Authorization: this.$store.state.token,
          },
        },
      });

      // Set Pusher Connection Bindings
      pusher.connection.bind("connected", () => {
        this.$store.commit("setConnectionStatus", "Connected");
        // Set for Loading
        this.$store.commit("setIsConnectionEstablished");
        this.getRegions();
      });
      pusher.connection.bind("connecting", () => {
        this.$store.commit("setConnectionStatus", "Connecting...");
      });
      pusher.connection.bind("disconnected", () => {
        this.$store.commit("setConnectionStatus", "Disconnected");
      });
      pusher.connection.bind("unavailable", () => {
        this.$store.commit("setConnectionStatus", "Unavailable");
      });

      // Subscribe to Pusher Channel
      var channel = pusher.subscribe("presence-resla-ops");

      // Subscribe to Refresh Requests
      channel.bind("REFRESH", (data) => {
        console.log(data);
        location.reload(true); //Hard refresh page
      });

      // Subscribe to DB Updates
      channel.bind("UPDATE", (data) => {
        if (data.table === "Reservations") {
          if (data.record.status !== "Started") {
            this.$store.commit("updateReservation", data.record);
          } else {
            console.log(
              "Skipped updating reservation in status: Started",
              data.record
            );
          }
        }
        if (data.table === "Vehicles") {
          this.$store.commit("updateVehicle", data.record);
        }
        if (data.table === "Collections") {
          this.$store.commit("updateCollection", data.record);
        }
      });

      // Subscribe to DB Inserts
      channel.bind("INSERT", (data) => {
        if (data.table === "Reservations") {
          if (
            parseInt(data.record.region_id) ==
            parseInt(this.$store.state.region)
          ) {
            this.$store.commit("insertReservation", data.record);
          }
        }
        if (data.table === "Vehicles") {
          this.$store.commit("insertVehicle", data.record);
        }
        if (data.table === "Collections") {
          this.$store.commit("insertCollection", data.record);
        }
      });

      // Subscribe to DB Deletes
      channel.bind("DELETE", (data) => {
        if (data.table === "Reservations") {
          this.$store.commit("deleteReservation", data.record);
        }
        if (data.table === "Vehicles") {
          this.$store.commit("deleteVehicle", data.record);
        }
        if (data.table === "Collections") {
          this.$store.commit("deleteCollection", data.record);
        }
      });
    },
    getRegions: function () {
      this.$store.commit("startLoading");

      this.$axios
        .get(this.$store.state.root_url + "/regions", {
          params: { auth: true },
        })
        .then((response) => {
          console.log(response);

          // Set Data
          if (!this.$store.state.region) {
            this.$store.commit("setRegion", response.data[0]);
          }

          // Set for Loading
          this.$store.commit("setIsRegionGathered");

          // Get User
          this.getUser(this.$store.state.region);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("stopLoading");
        });
    },
    getUser: function (region) {
      this.$store.commit("startLoading");
      this.$axios
        .get(
          this.$store.state.root_url + "/profile?region=" + region.toString(),
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);

          // Set Region
          this.$store.commit("setRegion", response.data.region);

          // Set Data
          this.$store.commit("setUser", response.data);

          // Finish Setup
          this.$store.commit("finishSetup");

          // Stop Loading
          this.$store.commit("stopLoading");
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("stopLoading");
        });
    },
  },
  watch: {
    // Watcher for authenticated state
    "$store.state.authenticated": {
      handler(newStatus, oldStatus) {
        if (newStatus === true && oldStatus === false) {
          this.connect();
        }
      },
      // deep: true, // Only if authenticated state has nested properties
    },

    // Watcher for user region state
    "$store.state.region": {
      handler(newRegion, oldRegion) {
        if (newRegion !== oldRegion && oldRegion !== null) {
          // Handle the region change
          console.log(`Region changed from ${oldRegion} to ${newRegion}`);
          this.getUser(parseInt(newRegion));
          // Implement your logic here
        }
      },
    },
  },
};
</script>

<style>
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 100;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Thin.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 300;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Light.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 400;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Regular.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 500;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Medium.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 700;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Bold.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 600;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-DemiBold.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 800;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ExtraBold.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 900;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Heavy.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 100;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ThinOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 300;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-LightOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 400;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-RegularOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 500;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-MediumOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 700;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-BoldOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 600;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-DemiBoldOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 800;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ExtraBoldOblique.otf")
    format("opentype");
}
@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 900;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-HeavyOblique.otf")
    format("opentype");
}
h1 {
  font-weight: bold !important;
}
body {
  font-family: "VisbyCF", serif;
}
.navbar {
  background: #0000ff !important;
}
.btn,
.btn-secondary,
.btn-primary {
}
a,
a:hover {
  text-decoration: none !important;
  border-radius: 2rem !important;
  color: black;
}
.btn {
  font-weight: 500 !important;
}
.btn-white {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}
.card-header,
.card-footer {
  background-color: transparent;
}
.card,
.card-header,
.card-footer,
.modal-header,
.modal-footer {
  border: none !important;
}
.badge {
  border: blue 1px solid !important;
  font-weight: bold !important;
  vertical-align: middle !important;
  background-color: blue !important;
}
.badge-secondary {
  border: #d6faff 1px solid !important;
  background-color: #d6faff !important;
  color: blue !important;
}
.badge-tertiary {
  border: black 1px solid !important;
  background-color: white !important;
  color: black !important;
}
.badge-red {
  border: black 1px solid !important;
  background-color: #ff0000 !important;
  color: white !important;
}
.badge-green {
  border: #00ff00 1px solid !important;
  background-color: #00ff00 !important;
  color: white !important;
}
.badge-yellow {
  border: black 1px solid !important;
  background-color: yellow !important;
  color: black !important;
}
.badge-grey {
  border: grey 1px solid !important;
  background-color: grey !important;
  color: black !important;
}
.badge-white {
  border: black 1px solid !important;
  background-color: white !important;
  color: black !important;
}
button.active {
  background-color: blue !important;
}
.carousel-indicators [data-bs-target] {
  background-color: lightgrey;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.carousel-indicators {
  position: unset !important;
}
.btn-primary,
.dropdown-item:active {
  background-color: blue !important;
  border-color: blue !important;
  color: white !important;
  opacity: 100 !important;
}
.btn-secondary,
.btn-close {
  background-color: white !important;
  border-color: white !important;
  color: blue !important;
  opacity: 100 !important;
}
.btn-transparent,
.btn-transparent:hover {
  border-color: transparent !important;
  background-color: transparent !important;
}
.progress-bar {
  background-color: blue !important;
}
.blue {
  background-color: blue !important;
}
.black {
  background-color: black !important;
}
.grey {
  background-color: grey !important;
}
.white {
  color: white !important;
}
.highlighted {
  color: blue !important;
  background: linear-gradient(to top, #d6faff 50%, transparent 50%);
}
.highlighted-secondary {
  color: black !important;
  background: linear-gradient(to top, #d6faff 50%, transparent 50%);
}
.highlighted-desktop {
  color: black !important;
  background: linear-gradient(to top, #d6faff 50%, #d6faff 50%);
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.secondary-border {
  border-color: blue !important;
  border-width: 2px !important;
}
.tertiary-border {
  border-color: #d6faff !important;
  border-width: 2px !important;
}
.grey-border {
  border-color: grey !important;
}
.grey-color {
  color: darkgrey !important;
}
.primary-color {
  color: blue !important;
}
.offcanvas.offcanvas-top {
  height: 100vh;
}

.timeline-with-icons {
  border-left: 4px solid #d6faff;
  position: relative;
  list-style: none;
}
.timeline-with-icons-desktop {
  border-top: 4px solid #d6faff;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: white;
  color: blue;
  border-radius: 50%;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-3 {
  line-height: 1.1 !important;
}
.nav-link {
  font-size: 1rem !important;
}
.rounded-3 {
  border-radius: 0.85rem !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.selected-vehicle {
  box-shadow: blue 0px 2px 4px, blue 0px 0px 0px 5px;
}
.vue-tel-input {
  border: none !important;
}
.vti__input {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vti__dropdown {
  display: none !important;
}
.status-circle {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}
.Vue-Toastification__toast--default {
  background: #fff !important;
  color: #000 !important;
  font-family: "VisbyCF", serif !important;
}
</style>
